<template>
    <div style="height: auto;margin-bottom: 20px;width: 1200px;margin: 0 auto;padding-bottom: 20px">
        <div class="right_content_parent">
            <div class="right_content">
                <div style="height: auto">
                    <div
                            style="
              display: flex;
              justify-content: center;
              height: 70px;
              line-height: 70px;
              background-color: #1767E0;
              border: 1px solid #1767E0;
              color: white;
              font-size: 20px;
            "
                    >
            <span>{{ reportList.userName }}</span
            >&nbsp;&nbsp;
                        <span style="margin-left: 20px"
                        >科技型中小企业测评报告
              <span>({{ reportList.createTime }})</span></span
                        >
                    </div>
                    <div style="height:auto; border: 1px solid rgb(218, 226, 229)">
                        <div style="padding-top: 70px" class="div_center">
                            <Row :gutter="16">
                                <Col span="12">
                                    <div style="height: 220px">
                                        <div style="height: 44px; line-height: 44px">
                      <span
                              style="
                          width: 126px;
                          display: inline-block;
                          text-align: right;
                          font-size: 14px;
                          padding-right: 10px;
                        "
                      >企业科技人员占比</span
                      >
                                            <i-progress :percent="Number(datasplit(reportList.technicalStaff)[3])">
                                                <Icon type="checkmark-circled"></Icon>
                                                <span style="color: #1767E0"
                                                >{{ datasplit(reportList.technicalStaff)[3] }}分</span
                                                >
                                            </i-progress>
                                        </div>
                                        <div style="height: 44px; line-height: 44px">
                      <span
                              style="
                          width: 126px;
                          display: inline-block;
                          text-align: right;
                          font-size: 14px;
                          padding-right: 10px;
                        "
                      >企业研发投入指标</span
                      >
                                            <i-progress :percent="Number(datasplit(reportList.costExpenses)[3]>datasplit(reportList.sellingExpenses)[5]?
                                            datasplit(reportList.costExpenses)[3]:datasplit(reportList.sellingExpenses)[5])">
                                                <Icon type="checkmark-circled"></Icon>
                                                <span style="color: #1767E0"
                                                >{{ datasplit(reportList.costExpenses)[3]>datasplit(reportList.sellingExpenses)[5]?
                                            datasplit(reportList.costExpenses)[3]:datasplit(reportList.sellingExpenses)[5]}}分</span
                                                >
                                            </i-progress>
                                        </div>
                                        <div style="height: 44px; line-height: 44px">
                                            <span style="width: 126px; display: inline-block;text-align: right;font-size: 14px;padding-right: 10px;">企业科技成果指标</span>
                                            <i-progress
                                                    :percent="Number(datasplit(reportList.ipICount)[2]>datasplit(reportList.ipIiCount)[2]?datasplit(reportList.ipICount )[2] : datasplit(reportList.ipIiCount )[2])">
                                                <Icon type="checkmark-circled"></Icon>
                                                <span style="color: #1767E0">{{ datasplit(reportList.ipICount)[2]>datasplit(reportList.ipIiCount)[2]?datasplit(reportList.ipICount )[2] : datasplit(reportList.ipIiCount )[2] }}分</span>
                                            </i-progress>
                                        </div>
                                    </div>
                                </Col>
                                <Col span="12">
                                    <div
                                            style="font-size: 14px;height: 120px;border: 1px solid rgb(205, 240, 243);border-right: none;border-bottom: none;"
                                    >
                                        <Row>
                                            <Col span="6">
                                                <div class="table-box" style="height: 120px;line-height: 120px">
                                                    企业测评总得分
                                                </div>
                                            </Col>
                                            <Col span="18">
                                                <div class="table-box-double" style="height: 120px;">
                                                    <span style="color: #FDA736;font-size: 14px"> 自评得分
                                                        <b style="font-size: 32px">{{ reportList.evalScore||0 }}</b>分</span>
                                                    <div style="height: 42px;background: #F3F9FC;border-radius: 2px;text-align: center;line-height: 42px;font-size: 12px">
                                                        <div v-if="!reportList.evalResult ">
                                                            <Icon type="md-close-circle" color="#FF6160"/>
                                                            很遗憾，企业基本条件/科技人员占比不符合科技型中小企业申报标准
                                                        </div>
                                                        <div v-else>
                                                            <Icon type="md-checkmark-circle" color="#36C2CF"/>
                                                            恭喜，企业各项指标数据良好，符合科技型中小企业申报标准
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div style="padding: 50px 0" class="div_center">
                            <div style="font-size: 14px;border: 1px solid rgb(205, 240, 243);border-right: none;border-bottom: none;">
                                <Row>
                                    <Col span="6">
                                        <div class="table-box"
                                             style="height: 50px;text-align: center;background-color: #1767E0;color: white;"
                                             title="科技型中小企业认证基本条件达成情况">
                                            科技型中小企业认证基本条件达成情况
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">企业情况</div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">认定标准</div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">结果</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="6">
                                        <div class="table-box" style="height: 50px; text-align: center">企业注册地</div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">{{
                                            datasplit(reportList.placeOfRegistration)[0] }}
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">{{
                                            datasplit(reportList.placeOfRegistration)[1] }}
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">{{
                                            datasplit(reportList.placeOfRegistration)[2] }}
                                        </div>
                                    </Col>

                                    <Col span="6">
                                        <div class="table-box" style="height: 50px; text-align: center">职工人数</div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">{{
                                            datasplit(reportList.workForce)[0] }}
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">{{
                                            datasplit(reportList.workForce)[1] }}
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">{{
                                            datasplit(reportList.workForce)[2] }}
                                        </div>
                                    </Col>

                                    <Col span="6">
                                        <div class="table-box" style="height: 50px; text-align: center">上年度销售收入</div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">{{
                                            datasplit(reportList.sellingExpenses)[0]
                                            }}
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">{{
                                            datasplit(reportList.sellingExpenses)[1]
                                            }}
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">{{
                                            datasplit(reportList.sellingExpenses)[2]
                                            }}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div style="padding: 50px 0" class="div_center">
                            <div style="font-size: 14px;border: 1px solid rgb(205, 240, 243);border-right: none;border-bottom: none;">
                                <Row>
                                    <Col span="6">
                                        <div class="table-box"
                                             style="height: 50px;text-align: center;background-color: #1767E0;color: white;"
                                             title="科技型中小企业认证具体得分项">
                                            科技型中小企业认证具体得分项
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">结果</div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">评分区间</div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">得分</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span="6">
                                        <div class="table-box" style="height: 50px; text-align: center">科技人员占企业职工总数占比
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">
                                            {{parseInt(datasplit(reportList.technicalStaff)[1] * 100)}} %
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">
                                            {{datasplit(reportList.technicalStaff)[2] }}
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">
                                            {{datasplit(reportList.technicalStaff)[3] }}
                                        </div>
                                    </Col>

                                    <Col span="6">
                                        <div class="table-box" style="height: 50px; text-align: center">企业研发投入指标</div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">
                                            {{parseInt((datasplit(reportList.costExpenses)[3]>datasplit(reportList.sellingExpenses)[5]?
                                            datasplit(reportList.costExpenses)[1]:datasplit(reportList.sellingExpenses)[3])*100)}}%
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">
                                            {{datasplit(reportList.costExpenses)[3]>datasplit(reportList.sellingExpenses)[5]?
                                            datasplit(reportList.costExpenses)[2]:datasplit(reportList.sellingExpenses)[4]}}
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 50px">
                                            {{datasplit(reportList.costExpenses)[3]>datasplit(reportList.sellingExpenses)[5]?
                                            datasplit(reportList.costExpenses)[3]:datasplit(reportList.sellingExpenses)[5]}}
                                        </div>
                                    </Col>

                                    <Col span="6">
                                        <div class="table-box"
                                             style="height: 60px;line-height: 60px; text-align: center">企业科技成果指标
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 60px;line-height: 30px">
                                            I类知识产权数量{{ datasplit(reportList.ipICount)[0] }}项<br>II类知识产权{{
                                            datasplit(reportList.ipIiCount)[0] }}项
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 60px;line-height: 60px"
                                             v-if="datasplit(reportList.ipICount)[2] > datasplit(reportList.ipIiCount)[2]">
                                            {{ datasplit(reportList.ipICount)[1]}}
                                        </div>
                                        <div class="table-box-double" style="height: 60px;line-height: 60px" v-else>
                                            {{ datasplit(reportList.ipIiCount)[1]}}
                                        </div>
                                    </Col>
                                    <Col span="6">
                                        <div class="table-box-double" style="height: 60px;line-height: 60px"
                                             v-if="datasplit(reportList.ipICount)[2] > datasplit(reportList.ipIiCount)[2]">
                                            {{ datasplit(reportList.ipICount)[2]}}
                                        </div>
                                        <div class="table-box-double" style="height: 60px;line-height: 60px" v-else>
                                            {{ datasplit(reportList.ipIiCount)[2]}}
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import {examUrl} from "../plugins/http/baseUrl";

    export default {
        name: "examScienceReport",
        mounted() {
            // this.getReportFun()
            this.id = this.$route.query.id
            this.getReportFun()
        },
        computed: {},
        data() {
            return {
                transfer: true,
                reportList: [],
                id: 0
            };
        },
        methods: {
            // 得到报告
            async getReportFun() {
                let param = {
                    id: this.id,
                    status: '1',
                    userName: ''
                }
                let url = `${examUrl}/kxee/list?pageSize=1&pageNum=1`
                axios.post(url, param).then(res => {
                    let json = res.data
                    console.log('json', json)
                    if (json.code == 200 && json.data.list && json.data.list.length > 0) {
                        this.reportList = json.data.list[0]
                        this.reportList.createTime = this.reportList.createTime.split('T')[0]
                    }
                })

            },
            datasplit(arr) {
                if (arr) {
                    return arr.split(';')
                } else {
                    return ''
                }
            }
        },
    };
</script>

<style scoped lang="scss">
    /deep/ .ivu-input:focus {
        border: 1px solid rgba(205, 240, 243, 1) !important;
        box-shadow: none !important;
    }

    .ivu-input:focus {
        outline: none !important;
    }

    .yi_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
    }

    /deep/ .ivu-input-wrapper {
        vertical-align: baseline !important;
    }

    /deep/ .table_div .ivu-input {
        height: 50px !important;
        text-align: center !important;
        padding: 0 0;
        border-color: rgba(205, 240, 243, 1);
        border-bottom: none !important;
    }

    /deep/ .table_div .ivu-date-picker-rel .ivu-input {
        height: 40px !important;
        text-align: center !important;
        padding: 0 0;
        border: 1px solid #dcdee2 !important;

    }

    .first:last-child .ivu-input {
        border-bottom: 1px solid rgba(205, 240, 243, 1);
    }

    /deep/ .table_div2 .ivu-input {
        height: 50px !important;
        text-align: center !important;
        padding: 0 0;
        border-color: rgba(205, 240, 243, 1);
    }

    /deep/ .ivu-select {
        width: 50% !important;
    }

    /deep/ .ivu-input-wrapper {
        vertical-align: center !important;
    }

    /deep/ .ivu-steps-item.ivu-steps-status-finish .ivu-steps-tail > i:after {
        background-color: #1767E0 !important;
    }

    /deep/ .ivu-steps-item.ivu-steps-status-finish .ivu-steps-head-inner {
        border-color: #1767E0 !important;
    }

    /deep/ .ivu-steps-item.ivu-steps-status-finish .ivu-steps-head-inner span,
    .ivu-steps-item.ivu-steps-status-finish
    .ivu-steps-head-inner
    > .ivu-steps-icon {
        color: #1767E0 !important;
    }

    .table_div {
        height: 50px;
        text-align: center;
        line-height: 50px;
        width: 100%;
        border-right: 1px solid rgba(205, 240, 243, 1);
        border-bottom: 1px solid rgba(205, 240, 243, 1);
    }

    /deep/ .ivu-select-selection {
        border-radius: 0;
    }

    .table_div2 {
        height: 50px;
        text-align: center;
        line-height: 50px;
        width: 100%;
    }

    .table_div_two {
        border-right: none !important;
    }

    /deep/ .ivu-steps-item.ivu-steps-status-process .ivu-steps-head-inner {
        background: #1767E0;
        border: #1767E0;
    }

    /deep/ .ivu-steps-head-inner span {
    }

    /deep/ .ivu-steps .ivu-steps-title {
        display: block;
        margin-top: 25px;
    }

    /deep/ .ivu-table-cell {
        text-align: center !important;

        & > span {
            text-align: center !important;
        }
    }

    /deep/ .ivu-table th {
        height: 50px !important;
        text-align: center;
    }

    .ivu-table-row {
        height: 50px !important;
    }

    /deep/ .ivu-table td {
        height: 50px !important;
    }

    /deep/ .ivu-radio-inner:after {
        background-color: #1767E0;
    }

    .arrow_ld {
        color: white !important;
        display: inline-block;
        height: 14px;
        width: 14px;
        border-radius: 50%;
        background: #1767E0;
        line-height: 14px;
        text-align: center;
    }

    .div_first_td {
        background-color: #1767E0 !important;
        color: white;
    }

    .table-box {
        width: 100%;
        height: 55px;
        background-color: #f3fcfc;
        line-height: 55px;
        text-align: left;
        border-bottom: 1px solid rgba(205, 240, 243, 1);
        border-right: 1px solid rgb(205, 240, 243);
        padding: 0 10px;
        overflow: hidden;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .table-box2 {
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: left;
        border-bottom: 1px solid rgba(205, 240, 243, 1);
        border-right: 1px solid rgb(205, 240, 243);
        font-size: 14px;
    }

    .table-box-double {
        width: 100%;
        height: 55px;
        line-height: 55px;
        text-align: center;
        border-bottom: 1px solid rgb(205, 240, 243);
        border-right: 1px solid rgb(205, 240, 243);
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0 10px;
    }

    .ivu-progress {
        display: inline-block !important;
        width: 65% !important;
    }

    .div_center {
        width: 95%;
        margin-left: 50%;
        transform: translateX(-50%);
        border-bottom: 2px dotted rgb(218, 226, 229);
    }

    .neirong {
        margin-left: 130px;

        .download_span {
            color: blue;
            display: inline-block;
            margin-right: 30px;
        }

        p {
            display: inline-block;
            margin-bottom: 20px;
            font-size: 14px;
            color: black;
        }
    }

    .bottom_style {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        bottom: -110px;
    }

    .common_liucheng:nth-child(1) {
        border: none;
    }

    .common_liucheng {
        border-top: 2px dotted #f5f5f5;
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        & > span {
            display: block;
            padding-left: 5px;
            padding-top: 38px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            color: rgb(51, 51, 51);
            margin-bottom: 60px;
        }
    }

    .next_style {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right_real_content {
        margin-bottom: 50px;
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 40px;
        overflow: hidden;
    }

    .right_header {
        width: 95%;
        padding-top: 20px;
        height: 140px;
        position: relative;
        margin-bottom: 40px !important;
        left: 50%;
        transform: translateX(-50%);
        border-bottom: 2px dotted #f5f5f5;
        overflow: hidden;

        span {
            display: block;
            margin-bottom: 20px;
        }
    }

    .button_style {
        display: inline-block;
        background-color: #1767E0;
        z-index: 51;
        text-align: center;
        font-size: 14px;
        color: white;
        cursor: pointer;
        padding: 0 27px;
        line-height: 32px;
        height: 32px;
        border-radius: 4px;}

    .input_search,
    .input_search:focus {
        border-width: 1px;
        border-color: rgb(55, 197, 207);
        border-style: solid;
        border-radius: 2px;
        background-color: rgb(255, 255, 255);
        box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
        width: 300px;
        height: 40px;
        outline: none;
    }

    .isChoose {
        color: #37c5cf;
    }

    .right_content {
        width: 100%;
        /*border-width: 1px;*/
        /*border-color: rgb(218, 226, 229);*/
        /*border-style: solid;*/
        background-color: rgb(255, 255, 255);
        min-width: 900px;
        height: auto;
    }

    .right_content_parent {
        box-sizing: border-box;
        width: 100%;
        min-width: 960px;
        float: left;
        display: flex;
        justify-content: center;
        padding-top: 50px;
        position: relative;
        margin-bottom: 130px;
    }

    .left_tab_parent {
        float: left;
        min-width: 240px;
        width: 20%;
        background-color: rgb(250, 250, 252);

        .left_tab {
            margin-top: 60px;

            div {
                width: 210px;
                margin: 0 auto;
                height: 48px;
                line-height: 48px;
                font-size: 14px;
                box-sizing: border-box;
                padding-left: 31px;
                margin-bottom: 20px;
                border-width: 1px;
                border-color: rgb(235, 235, 235);
                border-style: solid;
                background-color: rgb(255, 255, 255);
                cursor: pointer;
            }
        }
    }

    /deep/ .demo-table-info-column {
        background-color: #f3fcfc;
        color: white;
    }

    /deep/ .ivu-table-tbody .demo-table-info-column {
        color: black !important;
    }

    .hidden_span {
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        white-space: pre-wrap;
        line-height: normal;
        margin-left: 10px;
        color: #333333;
    }

    .two-title {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin: 0 auto;
        font-weight: bold;
    }

    .two-row {
        width: 500px;
        height: 16px;
        line-height: 16px;
        margin: 14px 0 14px 30%;
        display: inline-block;
    }

    .two-item {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #333333;
    }
</style>
